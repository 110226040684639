import React, { useState } from 'react';
import giLogo from './assets/img/logo.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const OtpForm = () => {
    const [otp, setOtp] = useState('');
    const [apiBaseURL, setApiBaseUrl] = useState("https://api.budgets.gisolutions.co.nz:4476/")
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setOtp(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        // const axios = require('axios');
        // Handle OTP submission logic here
        console.log('Submitted OTP:', otp);
        console.log(`Token : ${token}`);
        // You can add your verification logic here
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${apiBaseURL}verify/${otp}`,
                headers: {}
            };
            console.log(`URL : ${config.url}`)
            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data.verified));
                    if (response.data.verified) {
                        // Redirect to the other page
                        localStorage.setItem('login', 'yes');
                        // localStorage.setItem('token',`${token}`)
                        navigate('/App');
                    } else {
                        setError('Invalid OTP. Please try again.');
                    }
                })
                .catch((error) => {
                    console.log(error);
                });


        } catch (error) {
            console.log(error);
            setError('An error occurred while verifying the OTP. Please try again.');
        } finally {
            setLoading(false);
        }


    };
    return (
        <>
            <Helmet>
                <title>GI Budgets | Login</title>
            </Helmet>
            <div className="container d-flex justify-content-center align-items-center vh-100">
                <div className="card p-4 w-50">
                    <div className="text-center mb-4">
                        <img src={giLogo} alt="Gi logo" className="w-50" />
                    </div>
                    {/* <h2 className="mb-4">OTP Submission Form</h2> */}
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="otpInput" className="form-label">
                                Enter OTP:
                            </label>
                            <input type="text" className="form-control" id="otpInput" value={otp} onChange={handleChange} />
                            <br></br>
                            {/* <label htmlFor="otpInput" className="form-label">
                            Token:
                        </label>
                        <input type="text" className="form-control" id="tokenInput" value={token} onChange={(e) => setToken(e.target.value)} /> */}
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Submit OTP
                        </button>
                        {error && (
                            <div className="alert alert-danger mt-3" role="alert">
                                {error}
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
};

export default OtpForm;
