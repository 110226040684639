// TableSkeletonLoader.js
import React from 'react';
import './TableSkeletonLoader.css';

const TableSkeletonLoader = () => {
  const skeletonRows = Array.from({ length: 8 }); // Adjust the number of skeleton rows

  return (
    <div className="skeleton-loader">
      {skeletonRows.map((_, index) => (
        <div key={index} className="skeleton-row"></div>
      ))}
    </div>
  );
};

export default TableSkeletonLoader;
